/** @jsx jsx */
import { jsx } from '@emotion/react'
import { formPages } from '../assets/styles/AboutTwo.styles'

const SuccessContent = () => {
  return (
    <section css={formPages}>
      <div>
        <iframe src="https://forms.monday.com/forms/embed/29e5e32f0bcd3b5f0ecea845ac76ab15?r=use1" width="650" height="1700"  />
      </div>
    </section>
  )
}

export default SuccessContent
